class Calculator {

    constructor(fromColour, toColour) {

        // colours
        this.fromColour = fromColour;
        this.toColour = toColour;

        // rgb values
        this.orig_rgb = { r: fromColour.r, g: fromColour.g, b: fromColour.b };
        this.dest_rgb = { r: toColour.r, g: toColour.g, b: toColour.b };

		// fruits
		this.apples = 0; // plus red
		this.plums = 0; // minus red
		this.pears = 0; // plus green
		this.valfruit = 0; // minus green
		this.berries = 0; // plus blue
		this.pineapples = 0; // minus blue
		
        this.order = [];
        
    }

    calc() {

        // diff
		let r_diff_raw = this.dest_rgb.r - this.orig_rgb.r;
		let g_diff_raw = this.dest_rgb.g - this.orig_rgb.g;
        let b_diff_raw = this.dest_rgb.b - this.orig_rgb.b;
        
		// rounding
		let r_diff = Math.round((r_diff_raw) / 10) * 10;
		let g_diff = Math.round((g_diff_raw) / 10) * 10;
		let b_diff = Math.round((b_diff_raw) / 10) * 10;
        
        // remainders
		let r_rem = Math.round((r_diff_raw - r_diff) / 5) * 5;
		let g_rem = Math.round((g_diff_raw - g_diff) / 5) * 5;
		let b_rem = Math.round((b_diff_raw - b_diff) / 5) * 5;
        
        // red
        this.valfruit += (r_diff > 0) ? r_diff / 10 : 0;
        this.pineapples += (r_diff > 0) ? r_diff / 10 : 0;

        this.pears += (r_diff < 0) ? (r_diff - (r_diff * 2))/10 : 0;
        this.berries += (r_diff < 0) ? (r_diff - (r_diff * 2))/10 : 0;

        if(r_diff > 0){
            this.updateOrder(r_diff/10, ['Valfruit', 'Pineapples']);
        } else{
            this.updateOrder((r_diff - (r_diff * 2))/10, ['Pears', 'Berries']);
        }

        // fine tuning
		if(r_rem === 5 && g_rem === -5 && b_rem === -5){ this.apples++; this.order.push('Apples'); }
		if(r_rem === -5 && g_rem === 5 && b_rem === 5){ this.plums++; this.order.push('Plums'); }

        // green
        this.plums += (g_diff > 0) ? g_diff / 10 : 0;
        this.pineapples += (g_diff > 0) ? g_diff / 10 : 0;

        this.apples += (g_diff < 0) ? (g_diff - (g_diff * 2))/10 : 0;
        this.berries += (g_diff < 0) ? (g_diff - (g_diff * 2))/10 : 0;

        if(g_diff > 0){
            this.updateOrder(g_diff/10, ['Plums', 'Pineapples']);
        } else{
            this.updateOrder((g_diff - (g_diff * 2))/10, ['Apples', 'Berries']);
        }

        // fine tuning
		if(g_rem === 5 && r_rem === -5 && b_rem === -5){ this.pears++; this.order.push('Pears'); }
		if(g_rem === -5 && r_rem === 5 && b_rem === 5){ this.valfruit++; this.order.push('Valfruit'); }

        // blue
        this.plums += (b_diff > 0) ? b_diff / 10 : 0;
        this.valfruit += (b_diff > 0) ? b_diff / 10 : 0;

        this.apples += (b_diff < 0) ? (b_diff - (b_diff * 2))/10 : 0;
        this.pears += (b_diff < 0) ? (b_diff - (b_diff * 2))/10 : 0;

        if(b_diff > 0){
            this.updateOrder(b_diff/10, ['Plums', 'Valfruit']);
        } else{
            this.updateOrder((b_diff - (b_diff * 2))/10, ['Apples', 'Pears']);
        }

		// fine tuning
		if(b_rem === 5 && r_rem === -5 && g_rem === -5){ this.berries++; this.order.push('Berries'); }
        if(b_rem === -5 && r_rem === 5 && g_rem === 5){ this.pineapples++; this.order.push('Pineapples'); }

        // remove redundant fruit
        if(this.apples > 0 && this.plums > 0){

            if(this.apples > this.plums){

                this.apples -= this.plums;

                for(var i = this.order.length -1; i >= 0 ; i--){
                    if(this.order[i] === 'Apples' && this.plums > 0){
                        this.order.splice(i, 1);
                        this.plums--;
                    }

                    if(this.order[i] === 'Plums'){
                        this.order.splice(i, 1);
                    }
                }

				this.plums = 0;

            } else{

                this.plums -= this.apples;

                for(var i = this.order.length -1; i >= 0 ; i--){
                    if(this.order[i] === 'Plums' && this.apples > 0){
                        this.order.splice(i, 1);
                        this.apples--;
                    }

                    if(this.order[i] === 'Apples'){
                        this.order.splice(i, 1);
                    }
                }

				this.apples = 0;

            }

        }

        if(this.pears > 0 && this.valfruit > 0){

            if(this.pears > this.valfruit){

                this.pears -= this.valfruit;

                for(var i = this.order.length -1; i >= 0 ; i--){
                    if(this.order[i] === 'Pears' && this.valfruit > 0){
                        this.order.splice(i, 1);
                        this.valfruit--;
                    }

                    if(this.order[i] === 'Valfruit'){
                        this.order.splice(i, 1);
                    }
                }

				this.valfruit = 0;

            } else{

                this.valfruit -= this.pears;

                for(var i = this.order.length -1; i >= 0 ; i--){
                    if(this.order[i] === 'Valfruit' && this.pears > 0){
                        this.order.splice(i, 1);
                        this.pears--;
                    }

                    if(this.order[i] === 'Pears'){
                        this.order.splice(i, 1);
                    }
                }

				this.pears = 0;

            }

        }

        if(this.berries > 0 && this.pineapples > 0){

            if(this.berries > this.pineapples){

                this.berries -= this.pineapples;

                for(var i = this.order.length -1; i >= 0 ; i--){
                    if(this.order[i] === 'Berries' && this.pineapples > 0){
                        this.order.splice(i, 1);
                        this.pineapples--;
                    }

                    if(this.order[i] === 'Pineapples'){
                        this.order.splice(i, 1);
                    }
                }

				this.pineapples = 0;

            } else{

                this.pineapples -= this.berries;

                for(var i = this.order.length -1; i >= 0 ; i--){
                    if(this.order[i] === 'Pineapples' && this.berries > 0){
                        this.order.splice(i, 1);
                        this.berries--;
                    }

                    if(this.order[i] === 'Berries'){
                        this.order.splice(i, 1);
                    }
                }

				this.berries = 0;

            }

        }

        return {
            feeding_order: this.order,
            fruits: [
                { fruit: 'Apples', amount: this.apples },
                { fruit: 'Plums', amount: this.plums },
                { fruit: 'Pears', amount: this.pears },
                { fruit: 'Valfruit', amount: this.valfruit },
                { fruit: 'Berries', amount: this.berries },
                { fruit: 'Pineapples', amount: this.pineapples }
            ]
        }

    }

    updateOrder(times, fruits) {

        let i = 1;

        while(i <= times){
            this.order.push(...fruits);
            i++;
        }

    }

}

export default Calculator;