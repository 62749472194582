import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';

import Calculator from '../routes/Calculator';
import Results from '../routes/Results';
import Privacy from '../routes/Privacy';
import NotFound from '../routes/NotFound';

import translateData from '../data/translations.json';

import Ad from './Ad';
import Flag from './Flag';
import ScrollToTop from './ScrollToTop';

import { ReactComponent as Home} from '../svg/home.svg';

class App extends React.Component {

	constructor(props){
		super(props);
		ReactGA.initialize('UA-55980620-2');
	}

	setLang = (lang) => {
		this.props.cookies.set('lang', lang, { path: '/', maxAge: 60 * 60 * 24 * 365 });
	}

	navigate = (url) => {
		this.props.history.push(url);
	}

    render(){

		let lang = this.props.match.params.lang || this.props.cookies.get('lang') || 'en';
		let translation = translateData[lang];

		ReactGA.pageview(this.props.location.pathname);

		document.title = translation.title;
		document.querySelector('html').setAttribute('lang', lang);
		document.querySelector('meta[name=Description]').setAttribute('content', translation.description);
		document.querySelector('meta[property="og:title"]').setAttribute('content', translation.title);
		document.querySelector('meta[property="og:description"]').setAttribute('content', translation.description);
        document.querySelector('link[rel=manifest]').setAttribute('href', `/manifest-${lang}.json`);

        return (
			<div className="app">
				<ScrollToTop />
				<header>
					<Link to={`/${lang}`} className="home" aria-label="Home"><Home /></Link>
					<div className="flags">
						<Flag language="en" url={this.props.location.pathname} currentLang={lang} setLang={this.setLang} />
						<Flag language="de" url={this.props.location.pathname} currentLang={lang} setLang={this.setLang} />
						<Flag language="fr" url={this.props.location.pathname} currentLang={lang} setLang={this.setLang} />
						<Flag language="jp" url={this.props.location.pathname} currentLang={lang} setLang={this.setLang} />
					</div>
				</header>
				<Ad />
				<main>
                    <Switch>
                        <Route exact path="/" render={(props) => (<Calculator {...props} lang={lang} navigate={this.navigate} />)} />
                        <Route exact path="/:lang(en|fr|de|jp)" render={(props) => (<Calculator {...props} lang={lang} navigate={this.navigate} />)} />
                        <Route exact path="/:lang(en|fr|de|jp)/:from/:to" render={(props) => (<Results {...props} lang={lang} />)} />
						<Route exact path="/:lang(en|fr|de|jp)/privacy" component={Privacy} />
						<Route exact path="/privacy" component={Privacy} />
						<Route exact path="/:from/:to" render={(props) => (<Results {...props} lang={lang} />)} />
                        <Route path="*" component={NotFound} />
                    </Switch>
				</main>
				<footer>
					<Ad />
					<p className="copyright" dangerouslySetInnerHTML={{__html: translation.created_by}} />
					<nav><Link to={`/${lang}/privacy`}>Privacy Policy</Link></nav>
					<p className="trademark">{translation.trademark}</p>
				</footer>
			</div>
        );

    }

}

export default withCookies(App);