import React from 'react';

import { ReactComponent as Facebook} from '../svg/facebook.svg';
import { ReactComponent as Twitter} from '../svg/twitter.svg';

class SocialButton extends React.Component{

    share = () => {
        window.open(this.props.url,'sharer','toolbar=0,status=0,width=560,height=452');
    }

    render(){

        const SVG = {
            Twitter: <Twitter />,
            Facebook: <Facebook />,
          };

        return <button onClick={this.share} className={`social-button social-button--${this.props.buttonType.toLowerCase()}`}>{SVG[this.props.buttonType]} {this.props.label}</button>

    };

}

export default SocialButton;