import React from 'react';

class ColourPicker extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            categories: ['White', 'Red', 'Brown', 'Yellow', 'Green', 'Blue', 'Purple']
        };

    }

    selectCategory = (category) => {
        this.props.updateCategory(category);
    }

    selectColour = (colour) => {
        this.props.selectDesired(colour);
    }

    render(){
        return (
            <div className="colour-picker" aria-hidden="true">
                <p><b>{ this.props.label }</b></p>
                <div className="colour-picker__categories">{
                    this.state.categories.map((category, key) => {
                        return <div className={`colour-picker__category ${category}${this.props.category === category ? ' active' : ''}`} key={key} onClick={() => this.selectCategory(category)} />
                    })

                }</div>
                <div className="colour-picker__colours">
                    {
                        this.props.colours
                        .filter((colour) => colour.category === this.props.category)
                        .sort((a, b) => a.colour_order - b.colour_order)
                        .map((colour, key) => {
                            return <div className={`colour-picker__colour${this.props.toColour.hex === colour.hex ? ' active' : ''}`} style={{backgroundColor: colour.hex}} key={key} onClick={() => this.selectColour(colour)} />
                        })
                    }
                </div>
                <p className="result-img" style={{marginTop: '1em', marginBottom: 0}}><img src={`/img/colours/${this.props.toColour.url}.png`} alt="" /></p>
            </div>
        )
    }

}

export default ColourPicker;