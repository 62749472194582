import React from 'react';
import ReactGA from 'react-ga';

import SocialButton from '../components/SocialButton';
import NotFound from '../routes/NotFound';

import colourData from '../data/colours.json';
import translateData from '../data/translations.json';

import { ReactComponent as Chevron} from '../svg/chevron.svg';
import { ReactComponent as Check} from '../svg/check.svg';

import Calculator from '../Calculator'

class Results extends React.Component {

	constructor(props){
		super(props);

			this.fruits = {
				Apples:     {lid: "cb234eb78c5", en: "Xelphatol Apple", de: "Xelphatol-Apfel", fr: "Pomme De Xelphatol", jp: "ゼルファトルの果実"},
				Plums:      {lid: "c038e59d7d8", en: "Doman Plum", de: "Doma-Pflaume", fr: "Prune De Doma", jp: "ドマの果実"},
				Pears:      {lid: "5c083d76f80", en: "Mamook Pear", de: "Mamook-Birne", fr: "Poire Alligator De Mamook", jp: "マムークの果実"},
				Valfruit:   {lid: "2f6483575fb", en: "Valfruit", de: "Val-Frucht", fr: "Groseille Sanguine Du Val", jp: "バルの果実"},
				Berries:    {lid: "cf99ac467a9", en: "O'Ghomoro Berries", de: "O'Ghomoro-Beere", fr: "Raisin D'O'Ghomoro", jp: "オ・ゴモロの果実"},
				Pineapples: {lid: "a08107db41d", en: "Cieldalaes Pineapple", de: "Cieldaläen-Ananas", fr: "Ananas Des Cieldalaes", jp: "シェルダレーの果実"}
			};

		this.fromColour = colourData.colours.find((colour) => colour.url === this.props.match.params.from);
		this.toColour = colourData.colours.find((colour) => colour.url === this.props.match.params.to);

		let haveResults = true;
		let hanLemon = false;

		if(typeof this.fromColour === 'undefined' || typeof this.toColour === 'undefined'){
			haveResults = false;
		}
		else if(this.toColour.colour_en !== this.fromColour.colour_en && this.toColour.colour_en === 'Desert Yellow'){
			hanLemon = true;

			ReactGA.event({
				category: 'Calculator',
				action: 'Performed calculation',
				label: 'Han Lemon'
			  });
		} else{
			const Calc = new Calculator(this.fromColour, this.toColour);
			this.results = Calc.calc();
			ReactGA.event({
				category: 'Calculator',
				action: 'Performed calculation',
				label: this.toColour.colour_en
			  });
		}

		this.state = {
			haveResults: haveResults,
			hanLemon: hanLemon,
			showOrder: false,
			checked: []
		}

	}

	checkItem = (key) => {

		let checked = [];

		if(this.state.checked.some((x) => x === key)){
			checked = this.state.checked.filter((x) => x !== key);
		} else{
			checked = [key, ...this.state.checked];
		}

		this.setState({checked});
	};

	toggleOrder = () => {
		let toggle = !this.state.showOrder;
		this.setState({ showOrder: toggle });
	};

	render() {

		let lang = this.props.lang || 'en';
		let translation = translateData[lang];
		let lodestoneLang = lang === 'en' ? 'eu' : lang;

		let results;
		let fail = false;

		let title = `${this.fromColour[`colour_${lang}`]} → ${this.toColour[`colour_${lang}`]} - ${translation.title}`;
		let description = translation.results_1;

		document.title = title;
		document.querySelector('html').setAttribute('lang', lang);
		document.querySelector('meta[name=Description]').setAttribute('content', description);
		document.querySelector('meta[property="og:title"]').setAttribute('content', title);
		document.querySelector('meta[property="og:url"]').setAttribute('content', 'https://ffxivchocobo.com'+this.props.location.pathname);
		document.querySelector('meta[property="og:image"]').setAttribute('content', `https://ffxivchocobo.com/img/social-colours/${this.toColour.url}.png`);
		document.querySelector('meta[property="og:description"]').setAttribute('content', description);

		if(this.state.hanLemon){
			results = <React.Fragment><p>{translation.results_1}</p><ul className="result-list"><li><img src="/img/fruits/Han-Lemon.png" alt="" /><a href={`https://${lodestoneLang}.finalfantasyxiv.com/lodestone/playguide/db/item/d0a3f45a4a2/`} target="_blank" rel="noopener noreferrer" className="eorzeadb_link">{translation.han_lemon}</a> &times; 1</li></ul><p className="result-img"><img src={`/img/colours/${this.toColour.url}.png`} alt="" /></p></React.Fragment>;
		} else if(this.results.fruits.some((x) => x.amount > 0)){
			results = <React.Fragment><p>{translation.results_1}</p><ul className="result-list">
			{this.results.fruits.filter((x) => x.amount > 0).map((fruit, key) => {
				return <li key={key}><img src={`/img/fruits/${fruit.fruit}.png`} alt="" /><a href={`https://${lodestoneLang}.finalfantasyxiv.com/lodestone/playguide/db/item/${this.fruits[fruit.fruit].lid}/`} target="_blank" rel="noopener noreferrer" className="eorzeadb_link">{this.fruits[fruit.fruit][lang]}</a> &times; {fruit.amount}<br /></li>
			})}</ul><p className="result-img"><img src={`/img/colours/${this.toColour.url}.png`} alt="" /></p></React.Fragment>;
		} else {
			fail = true;
			results = <p style={{textAlign: 'center'}}><b>Kweh?!</b></p>;
		}

		return this.state.haveResults ? (
			<div className="content">
				<h1>{translation.title}</h1>
				<p className="result-txt">
					<span style={{color: this.fromColour.hex}}>{this.fromColour[`colour_${lang}`]}</span>
					<span className="result-txt__chevron"><Chevron /></span>
					<span style={{color: this.toColour.hex}}>{this.toColour[`colour_${lang}`]}</span>
				</p>
				{ results }
				{!fail && 
				<div className="social-button__center">
					<SocialButton buttonType="Twitter" label={translation.share_on_twitter} url={`https://twitter.com/intent/tweet?text=${encodeURI(title+' - https://ffxivchocobo.com'+this.props.location.pathname)}`} />
					<SocialButton buttonType="Facebook" label={translation.share_on_facebook} url={`https://www.facebook.com/sharer.php?u=${encodeURI('https://ffxivchocobo.com'+this.props.location.pathname)}`} />
				</div>}
				{!fail && 
				<div dangerouslySetInnerHTML={{__html: translation.results_2}} />
				}
				{ !this.state.hanLemon && this.results.feeding_order.length > 0 && 
				<button className={`button button--center button--chevron${ this.state.showOrder ? ' active' : '' }`} onClick={this.toggleOrder}>{translation.feeding_order}</button>
				}
				<ul className={`checklist${ !this.state.showOrder ? ' hide' : '' }`}>{
					 !this.state.hanLemon && this.results.feeding_order.length > 0 && this.results.feeding_order.map((fruit, key) => {
						return <li className={this.state.checked.some((x) => x === key ) ? 'checked' : '' } key={key}><button onClick={() => {this.checkItem(key)}}><img src={`/img/fruits/${fruit}.png`} /><span>{this.fruits[fruit][lang]}</span> <Check /></button></li>
					})
				}</ul>
			</div>
		) : <NotFound />;
	}

}

export default Results;