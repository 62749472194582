import React from 'react';
import Select from '../components/Select';
import ColourPicker from '../components/ColourPicker';
import SocialButton from '../components/SocialButton';

import colourData from '../data/colours.json';
import translateData from '../data/translations.json';

class Calculator extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			fromColour: colourData.colours.find((colour) => colour.colour_en === 'Desert Yellow'),
			toColour: colourData.colours.find((colour) => colour.colour_en === 'Snow White'),
			colours: colourData.colours,
			showNotes: false,
			showResults: false,
			category: 'White'
		};

	}

	updateColour = (colour, name) => {
		let category = name === 'toColour' ? colour.category : this.state.category;
		this.setState({ [name]: colour, category: category });
	};

	updateCategory = (category) => {
		this.setState({ category: category });
	};

	selectDesired = (colour) => {
		this.updateColour(colour, 'toColour');
	};
	
	calculate = (event) => {
		event.preventDefault();
		this.props.navigate(`/${this.props.lang}/${this.state.fromColour.url}/${this.state.toColour.url}`)
	};

	toggleNotes = () => {
		let toggle = !this.state.showNotes;
		this.setState({ showNotes: toggle });
	}
	
	render() {

		let lang = this.props.lang || 'en';
		let translation = translateData[lang];

		return (
			<div className="content">
				<h1>{translation.title}</h1>
				<div dangerouslySetInnerHTML={{__html: translation.intro}} />
				<SocialButton buttonType="Twitter" label={translation.share_on_twitter} url={`https://twitter.com/intent/tweet?text=${encodeURI(translation.title+' - https://ffxivchocobo.com'+this.props.location.pathname)}`} />
				<SocialButton buttonType="Facebook" label={translation.share_on_facebook} url={`https://www.facebook.com/sharer.php?u=${encodeURI('https://ffxivchocobo.com'+this.props.location.pathname)}`} />
				<form onSubmit={this.calculate}>
					<Select label={translation.current_colour} name="fromColour" colours={this.state.colours} selected={this.state.fromColour} updateColour={this.updateColour} language={lang} />
					<ColourPicker label={translation.select_desired_colour} colours={this.state.colours} toColour={this.state.toColour} category={this.state.category} selectDesired={this.selectDesired} updateCategory={this.updateCategory} language={lang} />
					<Select label={translation.desired_colour} name="toColour" colours={this.state.colours} selected={this.state.toColour} updateColour={this.updateColour} language={lang} />
					<button className="button button--fill">{translation.calculate}</button>
				</form>
				<button className="how-it-works" onClick={this.toggleNotes}><b>{translation.how_it_works_title}</b></button>
				<div className={this.state.showNotes ? 'how-it-works__content' : 'hide' } dangerouslySetInnerHTML={{__html: translation.how_it_works}} />
			</div>
		);
	}
}

export default Calculator;