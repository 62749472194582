import React from 'react';

export default class Ad extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
}

render () {
    return (
          <ins className="adsbygoogle"
          data-ad-client="ca-pub-0105137334539480"
          data-ad-slot="1846293004" />
    );
  }
}