import React from 'react';

class Select extends React.Component {

	updateColour = (event) => {
		this.props.updateColour(this.props.colours.find((x) => x.hex === event.target.value), this.props.name);
	};

	render() {
		return (
			<React.Fragment>
				<label htmlFor={this.props.name} >{this.props.label}</label>
				<select name={this.props.name} id={this.props.name} onChange={this.updateColour} value={this.props.selected.hex} className="select">
					{
						this.props.colours.sort((a,b) => a[`colour_${this.props.language}`] < b[`colour_${this.props.language}`] ? -1 : 1).map((colour, key) => {
							return <option value={colour.hex} key={key}>{colour[`colour_${this.props.language}`]}</option>
						})
					}
				</select>
			</React.Fragment>
		);
	}
}

export default Select;