import React from 'react';
import { Link } from 'react-router-dom';

let NotFound = () => (
	<div className="content">
		<h1>Kweh?!</h1>
		<p>Sorry, that page could not be found.</p>
		<Link to="/" className="button button--center">Go to the calculator</Link>
	</div>
);

export default NotFound;