import React from 'react';
import { Link } from 'react-router-dom';
import EN from '../svg/en.svg';
import DE from '../svg/de.svg';
import FR from '../svg/fr.svg';
import JP from '../svg/jp.svg';

class Flag extends React.Component {

    render() {

        const languages = {
            'en' : 'English',
            'de' : 'Deutsch',
            'fr' : 'Français',
            'jp' : '日本語'
        };

        return (
            <Link onClick={() => this.props.setLang(this.props.language)} to={`/${this.props.language+this.props.url.replace(/^\/(en|fr|de|jp)/, '' )}`}><img src={(function(language) {
                switch(language){
                    case 'en':
                        return EN;
                    case 'fr':
                        return FR;
                    case 'de':
                        return DE;
                    case 'jp':
                        return JP;
                    default:
                        return null;
                }
            })(this.props.language)} alt={languages[this.props.language]} /></Link>
        );

    }


}

export default Flag;