import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import App from './components/App';

const Router = () => (
	<CookiesProvider>
		<BrowserRouter>
			<Switch>
				<Route path="/:lang(en|fr|de|jp)" component={App} />
				<Route path="*" component={App} />
			</Switch>
		</BrowserRouter>
	</CookiesProvider>
);

export default Router;